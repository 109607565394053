
import { Component, Vue } from "vue-property-decorator";
import { ProductCategory } from "@/models/entities/product-category.interface";
import { productCategories } from "@/services/api/productCategories.service";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import SearchModule from "@/store/modules/search.module";
import OverlayModule from "@/store/modules/overlay.module";
import CartModule from "@/store/modules/cart.module";

const overlayModule = getModule(OverlayModule, store);
const searchModule = getModule(SearchModule, store);
const cartModule = getModule(CartModule, store);

@Component
export default class LLCategoriesSelect extends Vue {
  private categories = [] as ProductCategory[];
  private lengths = [] as number[];
  private selectedCategory: number = null;

  private get visibleCategories(): ProductCategory[] {
    let visibleCategories = [] as ProductCategory[];

    const widthLimit =
      this.$vuetify.breakpoint.width -
      this.calcTextLength("Altre Categorie", "16px Inter", 52);
    let actualLength = 0;

    for (
      let categoryIdx = 0;
      categoryIdx < this.categories.length;
      categoryIdx++
    ) {
      actualLength += this.lengths[categoryIdx];
      if (actualLength < widthLimit) {
        visibleCategories.push(this.categories[categoryIdx]);
      } else {
        break;
      }
    }
    return visibleCategories;
  }

  private get otherCategories(): ProductCategory[] {
    let otherCategories = [] as ProductCategory[];
    this.categories.forEach(
      (category: ProductCategory, categoryIdx: number) => {
        if (!this.visibleCategories[categoryIdx]) {
          otherCategories.push(this.categories[categoryIdx]);
        }
      }
    );
    return otherCategories;
  }

  private get selectedCategoryInOthers(): boolean {
    return this.otherCategories.some(
      (category) => category.id == this.selectedCategory
    );
  }

  private get categoryId(): number {
    return searchModule.category;
  }

  private get smAndUp(): boolean {
    return this.$vuetify.breakpoint.smAndUp;
  }

  private async created(): Promise<void> {
    this.selectedCategory = this.categoryId;
    this.getProductCategories();
  }

  private async getProductCategories(): Promise<void> {
    overlayModule.showOverlay();
    const c = await productCategories.getAllProductCategories();
    this.categories = c.data;
    this.categories.unshift({
      companyName: null,
      id: null,
      companyID: null,
      categoryName: "Tutti i prodotti",
      categoryDescription: null,
      isEnabled: true,
      createdBy: null,
      createdOn: null,
    } as ProductCategory);
    this.setLengths();
  }

  private async changeCategory(id: number) {
    this.selectedCategory = id;
    if (!searchModule.src)
      await searchModule.retrieveProducts({
        params: {
          categoryID: id,
          search: searchModule.src,
        },
      });
    cartModule.changeOrderCompleted(false);
  }

  private setLengths(): void {
    this.categories.forEach((category: ProductCategory) => {
      this.lengths.push(
        this.calcTextLength(
          category.categoryName,
          "16px Inter",
          this.smAndUp ? 64 : 32
        )
      );
    });
  }

  private calcTextLength(text: string, font: string, adder?: number): number {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    const result =
      Number(context.measureText(text).width.toFixed(1)) + (adder ?? 0);
    canvas.remove();
    return result;
  }
}
